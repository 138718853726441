import { useState } from "react";
import { createBrowserClient } from "@supabase/ssr";
import type { SupabaseClient } from "@supabase/supabase-js";

export type TypedSupabaseClient = SupabaseClient;

export type SupabaseOutletContext = {
  supabase: TypedSupabaseClient;
  domainUrl: string;
};

type SupabaseEnv = {
  DATABASE_URL: string;
  SUPABASE_ANON_KEY: string;
};

type UseSupabase = {
  env: SupabaseEnv;
};

export const useSupabase = ({ env }: UseSupabase) => {
  const [supabase] = useState(() =>
    createBrowserClient(env.DATABASE_URL!, env.SUPABASE_ANON_KEY!),
  );

  return supabase;
};
