import React from "react";
import { HighlightInit } from "@highlight-run/remix/client";
import { ReportDialog } from "@highlight-run/remix/report-dialog";
import type { LoaderFunctionArgs } from "@remix-run/cloudflare";
import { json } from "@remix-run/cloudflare";
import {
  isRouteErrorResponse,
  Links,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError } from "@sentry/remix";

import { NewRelic } from "./utils/NewRelic.tsx";

import "./styles/tailwind.css";

import { useSupabase } from "./utils/supabase.js";
import {
  getSupabaseEnv,
  getSupabaseWithHeaders,
} from "./utils/supabase.server.ts";

export const loader = async ({ request, context }: LoaderFunctionArgs) => {
  const { headers } = getSupabaseWithHeaders({
    request,
    context,
  });

  return json(
    {
      env: getSupabaseEnv(context),
      HIGHLIGHT_PROJECT_ID: "kevvm4ye",
    },
    { headers },
  );
};

export const ErrorBoundary = () => {
  const error = useRouteError();
  console.log(error);
  captureRemixErrorBoundaryError(error);

  if (isRouteErrorResponse(error)) {
    return (
      <div>
        <h1>
          {error.status} {error.statusText}
        </h1>
        <p>{error.data}</p>
      </div>
    );
  } else if (error instanceof Error) {
    return (
      <div>
        <script src="https://unpkg.com/highlight.run"></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `
							H.init('kevvm4ye');
						`,
          }}
        />
        <h1>Error</h1>
        <p>{error.message}</p>
        <p>The stack trace is:</p>
        <pre>{error.stack}</pre>

        <ReportDialog />
      </div>
    );
  } else {
    return <h1>Unknown Error</h1>;
  }
};

export default function App() {
  const { env, HIGHLIGHT_PROJECT_ID } = useLoaderData<typeof loader>();
  // @ts-ignore
  const supabase = useSupabase({ env });

  return (
    <html lang="en">
      <HighlightInit
        projectId={HIGHLIGHT_PROJECT_ID}
        serviceName="chords"
        tracingOrigins
        networkRecording={{ enabled: true, recordHeadersAndBody: true }}
        excludedHostnames={[
          "https://bam.nr-data.net",
          "https://js-agent.newrelic.com",
        ]}
      />
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <NewRelic />

        <title>Chords</title>
        <Links />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
          rel="stylesheet"
        />
      </head>
      <body>
        <Layout>
          <Outlet context={{ supabase }} />
          <ScrollRestoration />
          <Scripts />
        </Layout>
      </body>
    </html>
  );
}

function Layout({ children }: React.PropsWithChildren<{}>) {
  return (
    <main>
      <header></header>
      {children}
    </main>
  );
}
